import React from "react"

import { Input, FormField } from "src/componentsV2/InputForm"

import lockIcon from "../images/lock.svg"
import { Title } from "../shared"
import { CardElementInput, FormPaymentStyled } from "./style"

const PaymentForm = () => {
  return (
    <FormPaymentStyled>
      <div className="flex justify-between mt-7 mb-3">
        <Title>Enter your card details</Title>
        <img src={lockIcon} />
      </div>

      <FormField
        name="card_name"
        title="Cardholder"
        placeholder="Cardholder"
        component={Input}
      />
      <CardElementInput />
    </FormPaymentStyled>
  )
}

export default PaymentForm

import styled from "styled-components"

export const ContainerRegister = styled.div`
  max-width: 850px;
  margin-top: 40px;
  margin-left: 180px;
  margin-bottom: 45px;
  .back {
    color: #676b87;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    text-decoration: none;
    img {
      margin-right: 13px;
    }
  }
  .logo {
    margin-top: 60px;
  }
  .wrapper {
    .wrapper-left {
      width: 100%;
      max-width: 430px;
    }
    .wrapper-right {
      margin-top: -94px;
      max-width: 360px;
    }
  }
  @media (max-width: 1024px) {
    margin-left: 100px;
  }
  @media (max-width: 992px) {
    margin: 15px 15px 45px;
    .wrapper-right {
      padding-left: 20px;
    }
  }
  @media (max-width: 767px) {
    .logo {
      margin-top: 20px;
    }
    .wrapper {
      flex-direction: column-reverse;
      .wrapper-right {
        padding-left: 0px;
        margin-top: 15px;
        max-width: 430px;
      }
    }
  }
`
export const Title = styled.div`
  color: #0f1722;
  font-family: "Calluna-Regular";
  font-size: 30px;
  line-height: 32px;
`

export const SubTitle = styled.div`
  color: #676b87;
  font-family: "OpenSans-Regular";
  font-size: 15px;
  line-height: 24px;
`

import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import { useFormState } from "src/components/Form"
import { plans, BillingCycle } from "src/componentsV2/shared/plansData"
import RegisterForm from "./RegisterForm"
import PlanInfo from "./PlanInfo"
import { ContainerRegister, Title } from "./shared"
import arrowIcon from "./images/icons/arrow.svg"
import logo from "../../images/logos/airmason_logo_dark.svg"
import useCurrentUser from "src/hooks/useCurrentUser"
import constant from "src/utils/constant"

const Register = (props: any) => {
  const { user } = useCurrentUser()
  const plansFormState = useFormState("plans")
  const plan =
    plansFormState &&
    plansFormState.values &&
    plansFormState.values.selectedPlan

  const billingCycleDefault =
    plansFormState &&
    plansFormState.values &&
    plansFormState.values.billingCycle
      ? BillingCycle.month
      : BillingCycle.year

  const [billingCycle, setBillingCycle] = useState(billingCycleDefault)

  const planData = plan && plans.find(planItem => planItem.name === plan)
  const planId =
    planData &&
    (billingCycle === BillingCycle.year
      ? planData.yearPlan
      : planData.monthPlan)

  const [userData, setUserData] = useState()

  useEffect(() => {
    if (user) {
      setUserData(user)
    }
  }, [user])

  function onChangeBillingCycle(value: BillingCycle): void {
    setBillingCycle(value)
  }

  return (
    <ContainerRegister>
      <Link to="/plans" className="back">
        <img src={arrowIcon} alt="Back to Pricing" />
        Back to Pricing
      </Link>
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="title">
        <Title className="mt-8">
          Start your {constant.trialDays}-day free trial now
        </Title>
      </div>
      <div className="flex justify-between wrapper">
        <div className="wrapper-left">
          <RegisterForm
            user={userData}
            planName={plan}
            planId={planId}
            {...props}
          />
        </div>
        <div className="wrapper-right">
          {planData && (
            <PlanInfo
              planData={planData}
              billingCycle={billingCycle}
              onChangeBillingCycle={onChangeBillingCycle}
            />
          )}
        </div>
      </div>
    </ContainerRegister>
  )
}

export default Register

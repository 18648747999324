import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"

import Form from "src/components/Form"
import useCurrentUser from "src/hooks/useCurrentUser"
import validateEmail from "src/utils/validateEmail"
import { FormContext } from "src/components/Form"
import { actionTypes } from "src/components/Form/reducer"
import { Input, ErrorMessage } from "src/componentsV2/InputForm"
import checkIcon from "src/componentsV2/Plans/images/icons/check.svg"
import LoadingIcon from "src/componentsV2/LoadingIcon"
import { useSubscriberEmail } from "src/hooks/useLocalStorage"
import getErrorMsgFromResponse from "src/utils/getErrorMsgFromResponse"

export const userFormName = "user"

export const FormStyled = styled(Form)`
  margin-top: 31px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`

const _EmailInputWithRealTimeValidation = ({
  className,
  error,
  dirty,
  disabled,
  ...props
}: any) => {
  const { validateExistingEmail } = useCurrentUser()
  const [validating, setValidating] = useState(false)
  const { dispatch } = useContext<any>(FormContext)
  const [isValid, setIsValid] = useState(false)
  const [subscriberEmail] = useSubscriberEmail()

  useEffect(() => {
    // Validate when user back to Plans and go to Register form (Register form has value)
    // Don't validate if that is value of loggedIn user (using disabled property to determine)
    if (subscriberEmail) {
      validate(subscriberEmail)
    }
  }, [])

  const dispatchFn = (error: string) => {
    dispatch({
      type: actionTypes.FORM_FIELD_ERROR,
      formName: userFormName,
      fieldName: "new_email",
      error,
    })
  }

  const validate = async (value: string) => {
    if (!value || !validateEmail(value)) {
      dispatchFn("Please enter a valid company email address.")
      setIsValid(false)
      return
    }

    setValidating(true)
    try {
      await validateExistingEmail({
        variables: {
          input: {
            email: value,
          },
        },
      })

      dispatchFn("")
      setIsValid(true)
    } catch (e) {
      const message = getErrorMsgFromResponse(e) || "unknown error"
      dispatchFn(message)
      setIsValid(false)
    } finally {
      setValidating(false)
    }
  }

  return (
    <div
      className={`
        ${className}
        ${!!error ? "has-error" : ""}
        ${isValid ? "has-valid" : ""}
        ${validating ? "validating" : ""}
        ${disabled ? "disabled" : ""}
      `}
    >
      <input
        {...props}
        disabled={disabled}
        onBlur={(e: any) => validate(e.target.value)}
      />
      <LoadingIcon className="loading-icon" />
      <img src={checkIcon} />
      <ErrorMessage>{error}</ErrorMessage>
    </div>
  )
}

export const EmailInputWithRealTimeValidation = Input.withComponent(
  _EmailInputWithRealTimeValidation
)

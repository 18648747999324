import { useState, useEffect } from "react"

interface Event {
  [key: string]: Function[]
}

const events: Event = {}

const onLocalStorageChange = (key: string, fn: Function) => {
  if (!events[key]) {
    events[key] = []
  }

  events[key].push(fn)
}

const offLocalStorageChange = (key: string, fn: Function) => {
  if (!events[key] || !fn) {
    return
  }

  let fns = events[key]

  fns = fns.filter(item => {
    return item !== fn
  })

  events[key] = fns
}

const emitLocalStorageChange = (key: string, value: any) => {
  if (!events[key]) {
    return
  }

  const fns = events[key]

  fns.forEach(fn => fn(value))
}

export const setLocalStorage = (key: string, value: string) => {
  const valueToStore = typeof value === "object" ? JSON.stringify(value) : value

  // Save to local storage
  window.localStorage.setItem(key, valueToStore)

  // emit event
  emitLocalStorageChange(key, value)
}

export const removeLocalStorage = (key: string) => {
  window.localStorage.removeItem(key)

  // emit event
  emitLocalStorageChange(key, null)
}

export const useLocalStorage = (key: string, initialValue?: any) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once

  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window == "undefined") {
      return null
    }

    // Get from local storage by key
    const item = window.localStorage.getItem(key)
    try {
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return item
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    setLocalStorage(key, value)
  }

  const removeValue = () => {
    removeLocalStorage(key)
  }

  const listener = (value: any) => {
    setStoredValue(value)
  }

  useEffect(() => {
    onLocalStorageChange(key, listener)

    return () => {
      offLocalStorageChange(key, listener)
    }
  }, [key])

  return [storedValue, setValue, removeValue]
}

export const useSubscriberEmail = () => {
  const [
    subscriberEmail,
    setSubscriberEmail,
    removeSubscriberEmail,
  ] = useLocalStorage("subscriber_email")

  return [subscriberEmail, setSubscriberEmail, removeSubscriberEmail]
}

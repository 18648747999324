/*  eslint-disable */
import React from "react"
import Register from "../componentsV2/Register"

const GetStartedPage = ({ ...props }) => (
  <React.Fragment>
    <Register {...props} />
  </React.Fragment>
)

export default GetStartedPage

import React from "react"
import styled from "styled-components"
import { CardElement } from "react-stripe-elements"

export const Input = styled(props => <input {...props} />)`
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  color: #0f1722;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  outline: none;
  background: white;
  margin-bottom: 20px;
  ::placeholder {
    color: #a5abb4;
    opacity: 1;
  }

  @media (max-width: 700px) {
    max-width: 100%;
  }
`

export const FormPaymentStyled = styled.div`
  border-top: 1px solid rgba(167, 170, 188, 0.24);
`

const CardNumberElementInput = styled((props: any) => (
  <Input as={CardElement} {...props} hidePostalCode />
))`
  height: 40.4px;
  width: 100%;
  padding: 10px 15px;
  color: #0f1722;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  outline: none;
  &:focus {
    border: 1px solid #000;
  }
`

export const CardElementInput = (_props: any) => {
  return <CardNumberElementInput />
}

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

import { BillingCycle, PlansProps } from "src/componentsV2/shared/plansData"
import constant from "src/utils/constant"
import RadioButton from "src/componentsV2/ui/RadioButton"

import { PlanInfoStyled, Row, TotalStyled } from "./style"

const Description = styled.div`
  ${tw`text-orange-1 bg-orange-3 text-sm text-center mx-auto items-center flex mt-6 font-bold justify-center`}
  padding: 7px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
  .star {
    ${tw`text-8 mr-3 font-medium`}
    line-height: 43px;
    padding-top: 0.875rem;
    line-height: 0;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`

const Index = ({
  planData,
  billingCycle,
  onChangeBillingCycle,
}: {
  planData: PlansProps
  billingCycle?: string
  onChangeBillingCycle: Function
}) => {
  const [firstBilledDate, setFirstBilledDate] = useState<string>("...")

  const isMonthBill = billingCycle === "month"
  const displayData = isMonthBill
    ? {
        cost: planData.costMonth,
        billCycle: "12 months",
      }
    : {
        cost: planData.costYearTotal,
        billCycle: "1 year",
      }

  // Lazy load moment.js

  const firstBilledDateHandler = () => {
    import(/* webpackChunkName: "moment" */ "moment").then(
      ({ default: moment }) => {
        const tomorrow = moment()
          .add(constant.trialDays, "days")
          .format("MMM DD, YYYY")

        setFirstBilledDate(tomorrow)
      }
    )
  }

  useEffect(() => {
    firstBilledDateHandler()
  }, [planData, billingCycle])

  function handleChange(value: string): void {
    onChangeBillingCycle(value)
  }

  const costYearTotalFormat = Intl.NumberFormat().format(
    planData.costYearTotal || 0
  )

  return (
    <React.Fragment>
      <PlanInfoStyled>
        <Row>
          <div className="flex items-center mb-1">
            <div className="name mr-4">{planData.name}</div>
            <div className="trial">Trial</div>
          </div>
          <div className="employee-range">
            <span>{planData.minEmployeeRange}</span> to{" "}
            <span>{planData.maxEmployeeRange}</span> employees
          </div>
        </Row>
        <Row>
          <div>How would you like to pay?</div>
          <div className="mt-4 mb-6">
            <div className="my-6 flex items-center justify-between">
              <RadioButton
                name="annually"
                caption="Annually*"
                className="mr-6"
                captionClassName={!isMonthBill && `opacity-100 font-bold`}
                onChange={(value: string) => handleChange(value)}
                checked={!isMonthBill}
                value={BillingCycle.year}
              />
              <div>
                <span className="font-bold">${costYearTotalFormat}/</span>
                <span className="opacity-70">year </span>
              </div>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <RadioButton
                name="monthly"
                caption="Monthly"
                captionClassName={isMonthBill && `opacity-100 font-bold`}
                checked={isMonthBill}
                onChange={(value: string) => handleChange(value)}
                value={BillingCycle.month}
              />
              <div>
                <span className="font-bold">${planData.costMonth}/</span>
                <span className="opacity-70">month</span>
              </div>
            </div>
          </div>
          <Description>
            <span className="star">*</span>
            <span>Save up to 44% simply by paying annually!</span>
          </Description>
        </Row>
      </PlanInfoStyled>

      <TotalStyled>
        <Row>
          <div className="flex items-center justify-between">
            <div className="title-price">First billed {firstBilledDate}:</div>
            <div className="price">${displayData.cost}</div>
          </div>
        </Row>
        <Row>
          <div className="flex items-center justify-between">
            <div className="title-total">
              Total billed <span>Now</span>:
            </div>
            <div className="total-price">$0.00</div>
          </div>

          <div className="description mt-4">
            Your card will not be charged until after the {constant.trialDays}{" "}
            day free trial. We will also remind you two days before your free
            trial ends.
          </div>
        </Row>
      </TotalStyled>
    </React.Fragment>
  )
}

export default Index

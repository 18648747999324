import styled from "styled-components"
import planBg from "../images/plan_info_bg.png"

export const PlanInfoStyled = styled.div`
  background-image: url("${planBg}");
  background-repeat: no-repeat;
  padding: 40px 30px 30px;
  width: 100%;
  border-radius: 20px;
  background-color: #f5f8fb;
  background-position: 104px -290px;
  margin-bottom: 30px;
  .name {
    color: #0F1722;
    font-family: Calluna-Regular;
    font-size: 30px;
    line-height: 1;
  }
  .trial {
    height: 20px;
    width: 47px;
    border: 1px solid #ED9052;
    border-radius: 13.5px;
    color: #ED9052;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    line-height: 20px;
  }
  .employee-range {
    color: #0F1722;
    font-size: 16px;
    line-height: 20px;
    span {
      font-weight: bold;
    }
  }
  .title-price {
    color: #0F1722;
    font-size: 16px;
    line-height: 24px;
  }
  .price {
    color: #0F1722;
    font-size: 16px;
    line-height: 19px;
  }
  .sub-title {
    color: #676B87;
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: 767px) {
    background-position-x: 175px;
  }
`

export const Row = styled.div`
  border-bottom: 1px solid rgba(167, 170, 188, 0.24);
  padding: 20px 0px;

  &:first-child {
    padding-top: 0px;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
`

export const TotalStyled = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: #e4f9ed;
  padding: 26px 30px;
  .title-total {
    color: #0f1722;
    font-size: 20px;
    line-height: 24px;
    span {
      font-weight: bold;
    }
  }
  .total-price {
    color: #489091;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }
  .description {
    color: #489091;
    font-size: 16px;
    line-height: 19px;
  }
`
